

export enum RoleType {
  CUSTOMER = 'CUSTOMER',
  LECTURER = 'LECTURER',
  COORDINATOR = 'COORDINATOR',
  ORG_ADMIN = 'ORG_ADMIN',
  ORG_OWNER = 'ORG_OWNER',
  SYSTEM_ADMIN = 'SYSTEM_ADMIN',
};


