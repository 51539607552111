export interface IAcceptationRequirementsCustomParameter {
  value: any;
}

export interface IAcceptationRequirement {
  description: string;
  templateCode: AcceptationRequirementTemplateCode;
  customParameter?: IAcceptationRequirementsCustomParameter;
}

export enum AcceptationRequirementTemplateCode {
  MAXIMUM_AGE = 'MAXIMUM_AGE',
  MINIMUM_AGE = 'MINIMUM_AGE',
  REQUIRED = 'REQUIRED'
}

export const AcceptationRequirementTemplateCodeName = new Map<AcceptationRequirementTemplateCode, string>([
  [ AcceptationRequirementTemplateCode.MAXIMUM_AGE, 'Maximální věk' ],
  [ AcceptationRequirementTemplateCode.MINIMUM_AGE, 'Minimální věk' ],
  [ AcceptationRequirementTemplateCode.REQUIRED, 'Povinné' ]
]);
