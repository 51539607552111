import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject, take } from 'rxjs';
import { GetCustomDataResultType } from './entities/users/customer/customers.service';
import { DataType } from '../enums/collecting-data/data-type.enum';
import { dateToDateString, dateValidator } from '../components/date-input/date-input.component';
import { ICustomDataFG } from 'src/app/pages/admin/components/custom-data-inputs/custom-data-inputs.component';
import { BeUtilsService } from './BE/be-utils.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AcceptationRequirementTemplateCode } from '../models/collecting-data/acceptation-requirement.model';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  errs: string[] = [];

  constructor(
    private beUtilsService: BeUtilsService,
    private deviceService: DeviceDetectorService
  ) { }

  // premistit jinam
  public createCustomDataFG(customData: GetCustomDataResultType[], handleRequired = false): ICustomDataFG {
    const fg = new FormGroup<{[id: string]: FormControl}>({});
    customData.forEach(cd => {
      const validators = [];
      let value = cd.value;
      if (
        cd.dataType === DataType.BIRTHDATE || 
        cd.dataType === DataType.DATE) {
        validators.push( dateValidator.bind(this) );
        value = dateToDateString(new Date(value));
      }

      if (handleRequired && cd.params.acceptationRequirements?.find((r) => r.templateCode === AcceptationRequirementTemplateCode.REQUIRED)) {
        validators.push(Validators.required);
      }

      const FC = new FormControl(value, { nonNullable: true, validators: validators });
      fg.addControl(cd.id.toString(), FC);
    });
    return fg;
  }

  public logError(err: any, severity?: any) {
    console.error(err);

    if (this.errs.includes(err)) return;
    else {
      this.errs.push(err);
      this.beUtilsService.logFeError({
        error: err,
        currUrl: window.location.href,
        deviceInfo: this.deviceService.getDeviceInfo(),
        severity: severity
      }).pipe(take(1)).subscribe();
    }
    
  }

  public initializeState(initState: any) {
    let state: any = {}
    for (let [key, val] of Object.entries(initState)) {
      if (val instanceof BehaviorSubject) {
        state[key as keyof any] = new BehaviorSubject<any>((<BehaviorSubject<any>>val).getValue());
      } else if (val instanceof Subject) {
        state[key as keyof any] = new Subject();
      }
    }
    return state as any;
  }
  public resetState(initState: any, state: any) {
    for (let [key, val] of Object.entries(initState)) {
      if (val instanceof BehaviorSubject) {
        state[key as keyof any].next((<BehaviorSubject<any>>val).getValue())
      }
    }
  }

  public setOpacity = (hex: string, alpha: number) => `${hex}${Math.floor(alpha * 255).toString(16).padStart(2, '0')}`;


  /**
   * Recursively updates the validity of all controls within a form group or form array.
   * This method traverses the form structure, reaching into nested groups and arrays,
   * and calls updateValueAndValidity on each control to ensure the entire form's validity
   * state is up to date.
   *
   * @param control The form control, group, or array to update.
   */
  public updateFormValidity(control: AbstractControl): void {
    // If the control is a FormGroup, iterate over its controls and recursively update validity
    if (control instanceof FormGroup) {
      Object.values(control.controls).forEach((ctrl: AbstractControl) => this.updateFormValidity(ctrl));
    }
    // If the control is a FormArray, iterate over its controls and recursively update validity
    else if (control instanceof FormArray) {
      control.controls.forEach((ctrl: AbstractControl) => this.updateFormValidity(ctrl));
    }
    // Finally, update the validity of the current control.
    // The opts object with onlySelf: false ensures that the status of the parent controls is also updated.
    control.updateValueAndValidity({ onlySelf: false, emitEvent: false });
  }

  /**
   * Resets the value of a form control, group to initial state and removes all controls in FormArray.
   * @param control 
   */
  public deepFormReset(control: AbstractControl) {
    if (control instanceof FormGroup) {
      Object.values(control.controls).forEach((ctrl: AbstractControl) => this.deepFormReset(ctrl));
    } else if (control instanceof FormArray) {
      control.clear();
    } else if (control instanceof FormControl) {
      control.reset();
    } else {
      this.logError(`Unhandled control type ${control}, 102ouwjeorj09qwpoe`);
    }
  }

  public markFormGroupDirty(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      const a: any = formGroup.get(key);
      if (a instanceof FormGroup) {
        this.markFormGroupDirty(formGroup.get(key) as FormGroup);
      } else if (a instanceof FormArray) {
        this.markArrayDirty(formGroup.get(key) as FormArray);
      } else if (a instanceof FormControl) {
        this.markControlDirty(formGroup.get(key) as FormControl);
      }
    });
  }
  public markArrayDirty(formArray: FormArray) {
    formArray.controls.forEach(control => {
      if (control instanceof FormGroup) {
        this.markFormGroupDirty(control as FormGroup);
      } else if (control instanceof FormArray) {
        this.markArrayDirty(control as FormArray);
      } else if (control instanceof FormControl) {
        this.markControlDirty(control as FormControl);
      }
    });
  }
  public markControlDirty(formControl: FormControl) {
    formControl.markAsDirty();
  }
  public markUnknownControlDirty(control: FormArray | FormGroup | FormControl) {
    if (control instanceof FormArray) {
      this.markArrayDirty(control);
    } else if (control instanceof FormGroup) {
      this.markFormGroupDirty(control);
    } else if (control instanceof FormControl) {
      this.markControlDirty(control);
    } else {
      this.logError(`Unhandled control type ${control}, 102ouwjeorj09qwpoe`);
    }
  }

  public downloadMyFile(url: string){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', url);
    // link.setAttribute('download', `products.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public normalizeString(input: String) {
    return input.trim().toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "");
  }

  public sortAlphabetically(a: string, b: string) {
    return (this.normalizeString(a) > this.normalizeString(b)) ? 1 : ((this.normalizeString(b) > this.normalizeString(a)) ? -1 : 0);
  }

  public getNumberOfLeapYears(fromYear: number, toYear: number) {
    const getLeapYears = (year: number) => {
      year--;
      return Math.floor(year / 4) - Math.floor(year / 100) + Math.floor(year / 400);
    };
    return getLeapYears(toYear) - getLeapYears(fromYear);
  }

  getPlainText(content: string): string {
    // Create a new DOM parser
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');

    // Extract text content while preserving URLs
    const elements = Array.from(doc.body.childNodes);
    let plainTextWithUrls = this.parseNodes(elements);

    return plainTextWithUrls;
  }

  parseNodes(nodes: NodeListOf<ChildNode> | ChildNode[]): string {
    let result = '';
    
    nodes.forEach(node => {
      if (node.nodeType === Node.TEXT_NODE) {
        result += node.textContent;
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node as HTMLElement;
        if (element.tagName === 'A') {
          result += ` ${element.textContent}[${element.getAttribute('href')}] `;
        } else if (element.tagName === 'BR') {
          result += `\n`;
        } else if (element.tagName === 'P') {
          result += ` ${this.parseNodes(element.childNodes)}\n`;
        } else if (element.tagName === 'LI') {
          result += ` - ${this.parseNodes(element.childNodes)}\n`;
        } else {
          // Recursively process child nodes
          result += this.parseNodes(element.childNodes);
        }
      }
    });

    return result;
  }
}
