
<ng-container *ngIf="justInput">
  <ng-container *ngTemplateOutlet="input"></ng-container>
</ng-container>

<ng-container *ngIf="label">
  <!-- todo jak bude potreba -->
  <ng-container *ngTemplateOutlet="input"></ng-container>
</ng-container>

<ng-container *ngIf="floatingLabel">
  <span class="p-float-label">
    <ng-container *ngTemplateOutlet="input"></ng-container>
    <label>{{ floatingLabel }}</label>
  </span>
</ng-container>

<ng-template #input>

  <p-inputMask
    *ngIf="FC"
    #dateInput
    mask="99.99.9999"
    slotChar="dd.mm.rrrr"
    [formControl]="FC"
    placeholder="{{ placeholder }}"
    type="text"
    class="w-full"
    styleClass="w-full"
    [autoClear]="false"
    (keydown)="onDateKeyDown($event)">
  </p-inputMask>

</ng-template>