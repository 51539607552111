

export enum ImageSize {
  _64x64 = '64x64',
  _128x128 = '128x128',
  _256x256 = '256x256',
  _512x512 = '512x512',
  _1200x1200 = '1200x1200'
};

export const ImageSizeNumber = new Map<ImageSize, number[]>([
  [ ImageSize._64x64, [ 64, 64 ] ],
  [ ImageSize._128x128, [ 128, 128 ] ],
  [ ImageSize._256x256, [ 256, 256 ] ],
  [ ImageSize._512x512, [ 512, 512 ] ],
  [ ImageSize._1200x1200, [ 1200, 1200 ] ],
]);
